/* Hero section */
#hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/monica.jpg");
  min-height: calc(100vh - 80px); /* Adjust the value as needed */
  width: 100vw;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  background-attachment: fixed;
  overflow-y: auto;
}

.profile-container {
  position: absolute;
  top: 650px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-image {
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.profile-name {
  color: #fff;
  font-size: 4rem;
  font-weight: bold;
  margin-top: 2.2rem;
}

.profile-intro {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
  margin-top: 1.5rem;
}

/* Tabs */
.tabs-container {
  display: flex;
  justify-content: space-around; /* to distribute the buttons evenly across the horizontal axis */
  margin-top: 4rem; /* to move the buttons further down from the above content */
  margin-bottom: 0.5px;
}

.tabs-container button {
  width: 300px; /* adjust as needed */
  height: 150px; /* adjust as needed */
  padding: 10px 20px;
  margin: 0 3px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
  font-size: 1.5rem; /* adjust as needed */
  font-weight: bold; /* making the text bold */
  transition: background-color 0.3s ease;
}

.tabs-container button.selected {
  background-color: white;
  color: black;
}

.tabs-container button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Triangle and star */
.triangle {
  width: 0;
  height: 0;
  border-top: 51px solid black;
  border-right: 51px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.star {
  width: 27px; /* Adjust the width as needed */
  height: 27px; /* Adjust the height as needed */
  background-image: url('./images/star.png'); /* Path to your star image */
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -50px; /* Adjust the top position as needed to fit within the triangle */
  left: 1px; /* Adjust the left position as needed to fit within the triangle */
}

/* Responsive */
@media screen and (max-width: 768px) {
  .profile-intro {
    font-size: 0.8rem; /* Even smaller font size for phone screens */
  }

  .tabs-container button {
    width: 130px; /* Make each button take full width of the screen */
    height: 60px;
    padding: 10px 0; /* Adjust padding as needed */
    margin: 5px 0; /* Adjust margin for vertical spacing */
    font-size: 0.8rem;
    margin: 1px 1.5px;
  }

  .rectangle {
    width: 100%;
  }
}
